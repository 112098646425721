import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardOrderComponent } from './components/card-order/card-order.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgxPrintModule,
        TranslateModule
    ],
    declarations: [
        CardOrderComponent
    ],
    exports: [
        TranslateModule,
        CardOrderComponent
    ]
})
export class SharedModule { }